import { type FC } from 'react';
import { convertPrice } from './hooks/use-rendered-price';

interface Props {
  suffixLabel?: string;
  price: string | number;
  discountedPrice?: string | number | undefined;
  hasActiveDiscountedPrice?: boolean;
  showFirstMonth?: boolean;
  freeLabel?: string;
  className?: string
}

const Price: FC<Props> = function ({
  suffixLabel,
  price,
  discountedPrice,
  hasActiveDiscountedPrice = false,
  showFirstMonth = true,
  freeLabel,
  className = ''
}) {
  const currentPrice = hasActiveDiscountedPrice ? discountedPrice : price;
  const isFree = parseInt(convertPrice(currentPrice) ?? '0') <= 0;
  return (
    <div className={`text-right ${className}`}>
      {/* Regular price */}
      {hasActiveDiscountedPrice && (
        <div className="text-sm text-gray-400 line-through">
          {convertPrice(price)} € {suffixLabel}
        </div>
      )}

      {/* Actual price : discounted or regular depending of the context */}
      <div>
        {isFree && freeLabel ? (
          freeLabel
        ) : (
          <>
            {convertPrice(currentPrice)} €
            {suffixLabel && <span className="text-xs"> {suffixLabel}</span>}
          </>
        )}
      </div>

      {showFirstMonth && hasActiveDiscountedPrice && (
        <div className="text-xs">Le 1er mois</div>
      )}
    </div>
  );
};

export { Price };
