import { type FC, type FormEventHandler, memo } from 'react';
import { Heading } from '../heading';

type Props = {
  onSubmit: (couponCode: string) => Promise<boolean>;
};

const CouponForm: FC<Props> = memo(function ({ onSubmit }) {
  const handleSubmit: FormEventHandler<HTMLFormElement> = (evt) => {
    evt.preventDefault();
    const form = evt.target as HTMLFormElement;

    const couponCode = (form.elements.namedItem('coupon-code') as HTMLInputElement | null)?.value;
    if (couponCode !== undefined && typeof couponCode === "string" && couponCode.length > 0)
      onSubmit(couponCode.toUpperCase()).then((mustReset) => {
        if (mustReset) (evt.target as HTMLFormElement).reset();
      });
  };

  return (
    <form
      className=""
      onSubmit={handleSubmit}
    >
      <Heading as={'div'} className='mb-2'>Ajouter un code promo</Heading>
      {/* <GroupedInput
        type="text"
        name="coupon-code"
        placeholder="Code promo..."
        className="w-full uppercase"
        attrs={{
          className: 'uppercase !mt-[0]'
        }}

        trailing={
          <GhostButton size={'sm'}>Ajouter</GhostButton>
        }
      /> */}
    </form>
  );
});

CouponForm.displayName = 'CouponForm';
export { CouponForm };