/* eslint-disable react-hooks/exhaustive-deps */
import { type ResponseCartProductLineDTO } from '@repo/api-client';
import { X } from '@phosphor-icons/react/dist/ssr';
import { type FC, useContext, useEffect, useState } from 'react';
import Link from 'next/link';
import {
  type CartErrorEvent,
  renderProductName,
  renderProductUrl,
} from '../../utils';
import { convertNumberToDisplayablePriceString } from '@repo/utils';
import { GhostButton } from '../button/ghost-button';
import { CartContext } from './cart-controller.context';
import { LinkedProduct } from '../products/linked-product';
import { Price } from '../price';

type Props = {
  line: ResponseCartProductLineDTO;
  onRemove: () => void;
  onQtyChange: (qty: number) => void;
};

export const ProductLine: FC<Props> = function ({
  line,
  onRemove,
  // onQtyChange,
}) {
  const [qty, setQty] = useState(line.qty!);
  const cartController = useContext(CartContext);

  /**
   * Allow to restore line quantity back to safe value when an error ocurred
   */
  function handleCartRestoration(evt: CartErrorEvent) {
    if (evt.errorType !== 'BAD_REQUEST') return;

    const foundLine = evt.restoredCart.productLines.find(
      (l) => l.id === (line.id ?? -1)
    );
    setQty(foundLine?.qty ?? line.qty!);
  }

  useEffect(() => {
    cartController?.emitter.on('error', handleCartRestoration);

    return () => {
      cartController?.emitter.off('error', handleCartRestoration);
    };
  }, [line.qty]);

  // /**
  //  * Debounced callback to trigger the API call only after 300 secs
  //  */
  // const notify = useCallback(debounce(onQtyChange, 300), []);

  // /**
  //  * Updates the state and notify the controller
  //  * @param qty
  //  */
  // const handleQtyChange = (qty: number) => {
  //   if (qty < 1 || isNaN(qty) || undefined) {
  //     setQty(1);
  //     return;
  //   }

  //   setQty(qty);
  //   notify(qty);
  // };

  return (
    <>
      {/* Main product infos */}
      <div className="w-full">
        {/* Name & remove button */}
        <div className="flex flex-row items-center justify-between">
          <p className="font-bold">
            <Link
              href={renderProductUrl(line.product!.path!)}
              className="transition-colors hover:text-brand"
              target={'_blank'}>
              {renderProductName(line.product)}
            </Link>
          </p>
          <GhostButton onClick={() => onRemove()}>
            <X weight={'bold'} />
          </GhostButton>
        </div>

        {/* Price & quantity selector */}
        <div className="flex flex-row items-center justify-between">
          <div className="w-[130px]">
            {/* <GroupedInput
              name={`qty[${line.id}]`}
              value={qty.toString()}
              type={'number'}
              onChange={() => {}}
              attrs={{
                className: '!w-[45px] flex-none text-center',
                readOnly: true,
              }}
              leading={
                <GhostButton onClick={() => handleQtyChange(qty - 1)} size="sm">
                  <Minus weight="bold" />
                </GhostButton>
              }
              trailing={
                <GhostButton onClick={() => handleQtyChange(qty + 1)} size="sm">
                  <Plus weight="bold" />
                </GhostButton>
              }
            /> */}
          </div>

          <div className="text-gray-500">
            <Price 
              price={line.totals!.productsTotal!.allTaxesIncluded!}
              discountedPrice={line.totals?.total?.allTaxesIncluded}
              hasActiveDiscountedPrice={line.product.hasActiveDiscountedPrice}
              suffixLabel={"TTC"}
            />
          </div>
        </div>
      </div>

      {/* Linked products infos */}
      <ul className="mt-2 space-y-2 text-sm text-gray-500">
        {line.linkedLines &&
          line.linkedLines.map((linkedLine) => (
            <li key={linkedLine.id} className="">
              <div className="flex flex-row items-center">
                {/* Product infos */}
                <div className="w-6/12">
                  {/* Name */}
                  <div className="font-bold">
                    <LinkedProduct data={linkedLine.product} showPrice={false} />
                  </div>
                </div>
                {/* Price & quantity */}
                <div className="flex w-6/12 flex-row items-center justify-between">
                  <div className="pr-4">x {qty}</div>

                  <p>
                    {(linkedLine?.totals?.total?.allTaxesIncluded ?? 0) ===
                    0 ? (
                      'OFFERTS'
                    ) : (
                      <>
                        {convertNumberToDisplayablePriceString(
                          linkedLine?.totals?.total?.allTaxesIncluded
                        )}{' '}
                        € <span className="text-xs">TTC</span>
                      </>
                    )}
                  </p>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </>
  );
};
